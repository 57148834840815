import { Component, OnInit, ElementRef, ViewChild, Input, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-radar-chart',
  templateUrl: './radar-chart.component.html',
  styleUrls: ['./radar-chart.component.scss']
})
export class RadarChartComponent implements OnChanges, AfterViewInit {
  @ViewChild('chart', { static: false }) chart: ElementRef<SVGSVGElement>;
  @Input() data: { vixValue: number; stockPriceStrength: number; marketMomentum: number; greedFearIndex: number };

  private svg: any;
  private margin = { top: 50, right: 80, bottom: 50, left: 80 };
  private width: number;
  private height: number;
  private radius: number;


  ngAfterViewInit() {
    this.createChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && !changes.data.firstChange) {
      this.updateChart();
    }
  }

  private createChart(): void {
    if (!this.chart) {
      console.error('Chart reference is undefined');
      return;
    }

    const element = this.chart.nativeElement;
    const boundingRect = element.getBoundingClientRect();
    this.width = boundingRect.width - this.margin.left - this.margin.right;
    this.height = boundingRect.height - this.margin.top - this.margin.bottom;
    this.radius = Math.min(this.width, this.height) / 2;
  
    this.svg = d3.select(element)
      .append('g')
      .attr('transform', `translate(${this.width / 2 + this.margin.left},${this.height / 2 + this.margin.top})`);
  
    this.drawRadarChart();
  }

  private updateChart(): void {
    if (!this.chart) {
      console.error('Chart reference is undefined');
      return;
    }

    // Clear previous chart
    d3.select(this.chart.nativeElement).selectAll('*').remove();
    this.createChart();
  }

  private drawRadarChart(): void {
    if (!this.data) {
      console.error('Data is undefined');
      return;
    }
    
    const data = [
      { axis: 'VIX Value', value: this.data.vixValue },
      { axis: 'Stock Price Strength', value: this.data.stockPriceStrength },
      { axis: 'Market Momentum', value: this.data.marketMomentum },
      { axis: 'Greed/Fear Index', value: this.data.greedFearIndex }
    ];

    const maxValue = Math.ceil(Math.max(...Object.values(this.data)) / 10) * 10;

    // Set number of levels dynamically based on maxValue
    const levels = Math.ceil(maxValue / 10); // Adjust as needed for desired granularity
    const angleSlice = Math.PI * 2 / data.length;

    // Create the radar chart axis grid
    const radarLine = d3.lineRadial()
      .curve(d3.curveLinearClosed)
      .radius((d: any) => this.radius * (d.value / maxValue))
      .angle((d, i) => i * angleSlice);

    const axisGrid = this.svg.append('g').attr('class', 'axisWrapper');

    // Draw the background circles
    axisGrid.selectAll('.levels')
      .data(d3.range(1, (levels + 1)).reverse())
      .enter()
      .append('circle')
      .attr('class', 'gridCircle')
      .attr('r', (d) => {
        const r = this.radius / levels * d;
        return r < 0 ? 0 : r; // Ensure radius is non-negative
      })
      .style('fill', '#CDCDCD')
      .style('stroke', '#CDCDCD')
      .style('fill-opacity', 0.1);

    axisGrid.selectAll('.level-label')
      .data(d3.range(1, (levels + 1)).reverse())
      .enter()
      .append('text')
      .attr('class', 'level-label')
      .attr('x', -8)
      .attr('y', d => -this.radius / levels * d) // Position label
      .attr('text-anchor', 'middle')
      .style('font-size', '12px')
      .style('fill', '#ccd6f6')
      .text(d => Math.round((d / levels) * maxValue)); // Display numbers
    // Add axis lines
    const axis = axisGrid.selectAll('.axis')
      .data(data)
      .enter()
      .append('g')
      .attr('class', 'axis');

    axis.append('line')
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', (d, i) => this.radius * Math.cos(angleSlice * i - Math.PI / 2))
      .attr('y2', (d, i) => this.radius * Math.sin(angleSlice * i - Math.PI / 2))
      .attr('class', 'line')
      .style('stroke', 'white')
      .style('stroke-width', '2px');

    // Add labels
    axis.append('text')
      .attr('class', 'legend')
      .style('font-size', '12px') // Adjust font size
      .style('font-family', 'SF Mono, Fira Code, Fira Mono, Roboto Mono, Lucida Console, Monaco, Monospace') // Adjust font family
      .style('fill', '#ccd6f6') // Adjust font color
      .attr('text-anchor', 'middle')
      .attr('dy', '0.35em')
      .attr('x', (d, i) => this.radius * 1.40 * Math.cos(angleSlice * i - Math.PI / 2))
      .attr('y', (d, i) => this.radius * 1.1 * Math.sin(angleSlice * i - Math.PI / 2))
      .text(d => d.axis);

    // Draw the radar chart blobs
    this.svg.append('path')
      .datum(data)
      .attr('class', 'radarArea')
      .attr('d', radarLine)
      .style('fill', '#1f77b4')
      .style('fill-opacity', 0.5);

    this.svg.append('path')
      .datum(data)
      .attr('class', 'radarStroke')
      .attr('d', radarLine)
      .style('stroke-width', '2px')
      .style('stroke', '#1f77b4')
      .style('fill', 'none');

     // Add circles at each data point for the tooltip
     this.svg.selectAll('.radarCircle')
     .data(data)
     .enter()
     .append('circle')
     .attr('class', 'radarCircle')
     .attr('r', 4)
     .attr('cx', (d, i) => this.radius * (d.value / maxValue) * Math.cos(angleSlice * i - Math.PI / 2))
     .attr('cy', (d, i) => this.radius * (d.value / maxValue) * Math.sin(angleSlice * i - Math.PI / 2))
     .style('fill', '#ccd6f6')
     .style('fill-opacity', 0.8)
     .style('border', '2px')
     .style('border-color', 'ccd6f6')
     .style('border-style', 'solid')
     .on('mouseover', (event, d) => {
       tooltip.transition()
         .duration(200)
         .style('opacity', 1);
       tooltip.html(`${d.axis}: ${d.value}`)
         .style('left', (event.pageX) + 'px')
         .style('top', (event.pageY - 28) + 'px');
     })
     .on('mouseout', () => {
       tooltip.transition()
         .duration(500)
         .style('opacity', 0);
     });

   // Create a tooltip
   const tooltip = d3.select('body').append('div')
     .attr('class', 'tooltip')
     .style('position', 'absolute')
     .style('text-align', 'center')
     .style('padding', '6px')
     .style('font', '12px SF Mono')
     .style('background', '#112240')
     .style('border', '0px')
     .style('border-radius', '8px')
     .style('pointer-events', 'none')
     .style('opacity', 0); 
  }
}

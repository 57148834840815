<section class="section" id="payment">
  <div class="container">
    <div class="section-box" data-aos="flip-up" data-aos-duration="1000">
      <div class="cizeex-plus-title">
        <h3 class="e-font section-title">
          <span class="code-font n-section-title">05.</span>
          {{ "Header.Item6" | translate }}
        </h3>
      </div>
      <div class="payment-container">
        <!-- payment header -->
        <div class="payment-header">
          <h2>Manage Your Subscription</h2>
          <p>Pick an option</p>
        </div>
        <app-multiselect [items]="'SubscriptionInfo.Subscriptions' | translate" [itemTitle]="'Subscriptions'"
          [selectedItem]="planDesc" [extraDescription]="extraDescription" (itemClicked)="onItemClicked($event)">
        </app-multiselect>
        <form [formGroup]="paymentForm" (ngSubmit)="onComplete()">
          <div class="dropdown-container-space-around" *ngIf="showPaymentMethod">
            <select formControlName="paymentMethod" class="input-width-fixed">
              <option value="">Select a payment method</option>
              <option value="Credit Card">Credit Card</option>
              <option value="Apple Pay">Apple Pay</option>
              <option value="Google Pay">Google Pay</option>
            </select>
          </div>
          <button type="submit">
            <a class="btn btn-1" [class.disabled]="(!hasSubscription && manageSubscription[selectedSubscription] != manageSubscription.Upgrade) ||
            (paymentForm.invalid && manageSubscription[selectedSubscription] == manageSubscription.Upgrade)">
              <svg>
                <rect x="0" y="0" fill="none" width="100%" height="100%" />
              </svg>
              {{ buttonName }}
            </a>
          </button>
          <button (click)="onStartOver()">
            <a class="btn btn-1">
              <svg>
                <rect x="0" y="0" fill="none" width="100%" height="100%" />
              </svg>
              Start Over
            </a>
          </button>
          <app-loading></app-loading>
        </form>
        <p class="question-link">have a question? <a href="mailto:info@cizeex.com" target="_blank">Click here</a>
        </p>
      </div>
    </div>
  </div>
</section>
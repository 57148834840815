import { first } from 'rxjs/operators';
import { User } from 'src/app/models/user.model';
import { Plans } from 'src/app/enums/plans.enum';
import { Roles } from 'src/app/enums/roles.enum';
import { Component, OnInit } from '@angular/core';
import { Stripe } from 'src/app/models/stripe.model';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BannerService } from 'src/app/services/banner/banner.service';
import { StripeService } from 'src/app/services/stripe/stripe.service';
import { RegistrationSteps } from 'src/app/enums/registration-step.enum';
import { ManageSubscription } from 'src/app/enums/subscription-desc.enum';
import { LoadingService } from 'src/app/services/loading-service/loading.service';
import { MetaService } from 'src/app/services/meta/meta.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})

export class RegistrationComponent implements OnInit {
  // Private members
  private _auth: AuthService;

  // Public properties
  role: Roles;
  step: string;
  error: string;
  userInfo: User;
  stripeInfo: Stripe;
  selectedPlan: Plans;
  selectedRole: Roles;
  currentPlanId: string;
  currentStep: number = 1;
  registrationData: any = {};
  planDesc: ManageSubscription;
  stripeSubscriptionId: string;
  registrationSteps = RegistrationSteps;
  returnUrl: string = this.route.snapshot.queryParams['returnUrl'] || '/en';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private metaService: MetaService,
    private authService: AuthService,
    private bannerService: BannerService,
    private stripeService: StripeService,
    private loadingService: LoadingService) {
    this._auth = authService;
  }

  get auth(): AuthService {
    return this._auth;
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.selectedRole = parseInt(params.role, 10) as Roles;
      if (this._auth.currentUserValue) {
        this.currentStep = RegistrationSteps.Profile;
      }
      else {
        this.currentStep = RegistrationSteps.Registration;
      }
    });
    this.metaService.setMetaTags({
      title: 'Register on Cizeex',
      description: 'Access exclusive benefits and personalized insights',
      keywords: "Market Insights, Economic Calendar AI, Rejected, Learning Hub, Job Listings, articles"
    });
  }

  onUserDetailsCompleted(data: any): void {
    this.registrationData = { ...this.registrationData, ...data };
    this.submitRegistration();
  }

  onRoleSelected(role: string): void {
    this.registrationData.role = role;
    this.selectedRole = Roles[role];
    this.currentStep = RegistrationSteps.Plans;
  }

  onPlanSelected(plan: string): void {
    this.registrationData.plan = plan;
    this.selectedPlan = Plans[plan];
    this.currentStep = RegistrationSteps.Payment;
  }

  onPaymentCompleted(data: any): void {
    this.registrationData = { ...this.registrationData, ...data.paymentForm };
    this.onManageSubscription(data.selectedSubscription, data.hasSubscription);
  }

  onManageSubscription(manageSubscriptionId: ManageSubscription, hasSubscription: boolean) {
    const stripeSubscriptionId = this._auth.currentUserInfoValue.stripeSubscriptionId;
    const username = this._auth.currentUserInfoValue.username;
    switch (manageSubscriptionId) {
      case ManageSubscription.Upgrade:
        if (username && hasSubscription) {
          this.updateSubscription(this.selectedPlan, this.selectedRole);
        }
        else if (username) {
          this.initiateCheckout();
        }
        else
          this.submitRegistration();
        break;
      case ManageSubscription.Cancel:
        this.cancelSubscription(stripeSubscriptionId);
        break;
      case ManageSubscription.Pause:
        this.pauseSubscription(stripeSubscriptionId);
        break;
      case ManageSubscription.Resume:
        this.resumeSubscription(stripeSubscriptionId);
        break;
      default:
        this.bannerService.showBanner('An error initiating checkout occurred! Please try again!', 'error', false);
        break;
    }
  }

  submitRegistration(): void {
    //Use a service to handle registration logic
    this.authService.register(this.registrationData)
      .pipe(first())
      .subscribe(
        data => {
          this.registrationData = {};
          this.loadingService.hideSection();
          this.currentStep = RegistrationSteps.Registration;
          if (this.selectedRole > Roles.Cizeex) {
            this.currentStep = RegistrationSteps.Roles;
          }
          this.bannerService.showBanner('You have registered successfully!', 'success', true);
        },
        error => {
          this.error = error;
          this.loadingService.hideSection();
          this.currentStep = RegistrationSteps.Registration;
          this.bannerService.showBanner('An error occurred!', 'error', false);
        });
  }

  goBack() {
    this.router.navigate(['/']);
  }

  async onUpdateRole(roleId: Roles) {
    try {
      await this.stripeService.updateRole(roleId);
    } catch (error) {
      this.bannerService.showBanner('Error updating roles. Please try again later.', 'error', true);
    }
  }

  /** Strip functions */
  async initiateCheckout() {
    try {
      const session: any = await this.stripeService.createCheckoutSession(this.selectedPlan, this.selectedRole);
      await this.stripeService.redirectToCheckout(session);
    } catch (error) {
      this.bannerService.showBanner('Error upgrading subscription. Please try again later.', 'error', true);
    }
  }

  async updateSubscription(subscriptionId: Plans, roleId: Roles) {
    try {
      await this.stripeService.updateSubscription(subscriptionId, roleId);
    } catch (error) {
      this.currentStep = RegistrationSteps.Roles;
      console.error('Error pausing subscription', error);
    }
  }

  async cancelSubscription(subscriptionId: string) {
    try {
      await this.stripeService.cancelSubscription(subscriptionId);
    } catch (error) {
      console.error('Error pausing subscription', error);
    }
  }

  async pauseSubscription(subscriptionId: string) {
    try {
      await this.stripeService.pauseSubscription(subscriptionId);
    } catch (error) {
      console.error('Error pausing subscription', error);
    }
  }

  async resumeSubscription(subscriptionId: string) {
    try {
      await this.stripeService.resumeSubscription(subscriptionId);
    } catch (error) {
      console.error('Error resume subscription', error);
    }
  }
}

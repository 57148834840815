<section class="section" id="user-profile">
    <div class="container">
        <div class="section-box" data-aos="flip-up" data-aos-duration="1000">
            <div class="cizeex-plus-title">
                <h3 class="e-font section-title">
                    <span class="code-font n-section-title">05.</span>
                    {{ "Header.Item8" | translate }}
                </h3>
            </div>
            <div class="generic-fit-container">
                <!-- user info header -->
                <div class="user-info-header">
                    <h2>Profile Info</h2>
                    <p>Welcome to your profile! Here you can view your account details and manage your subscription. To update your information, click the 'Update Profile' button below.</p>
                </div>
                <div class="table-container padding-bottom-25">
                    <table>
                        <thead>
                            <tr>
                                <th>Username</th>
                                <th>Email Address</th>
                                <th>Current Plan</th>
                                <th>User Role</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ userInfo.username || 'N/A' }}</td>
                                <td>{{ userInfo.email || 'N/A' }}</td>
                                <td>{{ planName || 'No Plan' }}</td>
                                <td>{{ roleName || 'No Role' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <button (click)="onNext()">
                    <a class="btn btn-1">
                        <svg>
                            <rect x="0" y="0" fill="none" width="100%" height="100%" />
                        </svg>
                        Update Profile
                    </a>
                </button>
            </div>
        </div>
    </div>
</section>
import { Component } from '@angular/core';
import { StockNewsComponent } from './stock-news/stock-news.component';
import { EtfNewsComponent } from './etf-news/etf-news.component';
import { MarketNewsComponent } from './market-news/market-news.component';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrl: './news.component.scss'
})
export class NewsComponent {
  active = 0

  contentComponent: any = MarketNewsComponent;

  onTabSelected(index: number) {
    switch (index) {
      case 0:
        this.contentComponent = MarketNewsComponent;
        break;
      case 1:
        this.contentComponent = StockNewsComponent;
        break;
      case 2:
        this.contentComponent = EtfNewsComponent;
        break;
      default:
        break;
    }
  }

}

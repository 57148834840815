<div class="ticker-title">
    <form>
        <input type="text" [(ngModel)]="tickerSymbol" (ngModelChange)="onTickerInput($event)"
            placeholder="Enter Symbol (e.g., AAPL)" name="symbol" required />
    </form>
</div>
<div>
    <div *ngIf="result" class="ticker-checker-result bottom-border-1px" title={{result.symbol}}>
        <p *ngIf="result.available; else notFound" (click)="emitTicker()">
            <strong>{{ result.symbol }}</strong><br />
            {{ result.name }}<br />
            {{ result.sector }}<br />
        </p>
        <ng-template #notFound aria-disabled="true">
            <p><strong>{{ tickerSymbol }}</strong> is not available.</p>
        </ng-template>
    </div>
</div>
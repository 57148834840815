import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Plans } from 'src/app/enums/plans.enum';
import { Roles } from 'src/app/enums/roles.enum';
import { AuthService } from 'src/app/services/auth/auth.service';

export interface User {
  email: string;
  plan: string | Plans;
  role: string | Roles;
  username: string;
}

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {
  @Output() nextStep = new EventEmitter<string>();
  userInfo: User | null = null;
  planName: string;
  roleName: string;

  constructor(private _auth: AuthService) {}

  ngOnInit(): void {
    // Retrieve user info from auth service
    if (this._auth.currentUserInfoValue) {
      this.userInfo = this._auth.currentUserInfoValue;
      this.planName = Plans[this._auth.currentUserInfoValue.plan];
      this.roleName = Roles[this._auth.currentUserInfoValue.role];
    }
  }
  onNext(): void {
    this.nextStep.emit('');
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TickerService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  checkTickerAvailability(symbol: string): Observable<any> {
    const params = new HttpParams().set('symbol', symbol);
    return this.http.get(`${this.apiUrl}check_ticker`, { params });
  }
}

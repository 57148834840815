<section class="section" id="articles" title="Articles">
    <div class="container">
        <div class="section-box">
            <div class="cizeex-plus-title" data-aos="fade-up" data-aos-duration="1000">
                <h3 class="e-font section-title" alt="Articles">
                    <span class="code-font n-section-title">02.</span>
                    {{ "Article.Title" | translate }}
                </h3>
            </div>
            <p class="points-description" [innerHTML]="'Article.Description' | translate">
            </p>
            <div *ngIf="auth.currentUserInfoValue" class="icon-order">
                <button class="main-btn main-btn-icon" (click)="goToEditor()" title="Create New Article">
                    <i class="fas fa-plus-circle"></i>
                </button>
                <button class="main-btn main-btn-icon"
                    (click)="yourFiles = !yourFiles; fetchNew(auth.currentUserInfoValue.userId);" title="Your Articles">
                    <i class="fas fa-person-booth"></i>
                </button>
                <button disabled class="main-btn main-btn-icon" title="Create Daily AI Articles with Cizeex+">
                    <i class="fas fa-robot"></i>
                </button>
            </div>
            <div *ngIf="!(articles && articles.length > 0) || !auth.currentUserInfoValue" class="padding-bottom-25">
                <p data-aos="fade-up" data-aos-duration="1000" class="points-description">You need to <a routerLink="/login">log in</a> to add a new article.</p>
            </div>
            <app-loading *ngIf="!hasData"></app-loading>
            <div *ngIf="hasData" @fade>
                <app-grid [data]="articles" [dateKey]="'createdAt'" [aiReactionOff]="true" [datetimeKey]="'createdAt'"
                    [excludeHeaders]="['id', 'coverimageurl', 'authorid']"
                    [orderHeaders]="['title', 'authorName', 'likes', 'views']"
                    [enableResizeView]="true" (rowClicked)="onRowClicked($event)">
                </app-grid>
            </div>
        </div>
    </div>
</section>
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MoneyFlowTimeline } from 'src/app/components/market/interface/money-flow-timeline';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MoneyFlowTimelineService {
  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getMoneyFlowTimeline(timeline: string, assets: any): Observable<MoneyFlowTimeline[]> {
    const payload = { timeline_string: timeline, assets: assets };
    return this.http.post<MoneyFlowTimeline[]>(`${this.apiUrl}get_monthly_trends`, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(
      catchError(error => {
        console.error('Error in retrieving money flow timeline data:', error);
        return throwError(() => new Error('Error in retrieving money flow timeline data.'));
      })
    );
  }
}

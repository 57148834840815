<div class="container">
  <app-user-info
    *ngIf="currentStep === registrationSteps.Profile"
    (nextStep)="currentStep = registrationSteps.Roles;"
  ></app-user-info>
  <app-user-details
    *ngIf="currentStep === registrationSteps.Registration"
    [isCodeCollaborate]="role > 1"
    (nextStep)="onUserDetailsCompleted($event)"
  ></app-user-details>

  <app-role-selection
    *ngIf="currentStep === registrationSteps.Roles"
    [role]="selectedRole"
    (nextStep)="onRoleSelected($event)"
    (updateRoleEvent)="onUpdateRole($event)"
  ></app-role-selection>

  <app-plan-selection
    *ngIf="currentStep === registrationSteps.Plans"
    [selectedRole]="selectedRole"
    (nextStep)="onPlanSelected($event)"
  ></app-plan-selection>

  <app-payment-information
    *ngIf="currentStep === registrationSteps.Payment"
    [selectedPlan]="selectedPlan"
    [planDesc]="planDesc"
    [stripe]="stripeInfo"
    (completeRegistration)="onPaymentCompleted($event)"
    (startOver)="currentStep = registrationSteps.Roles;"
  ></app-payment-information>
</div>

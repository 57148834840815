import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { GeneralModule } from '../general/general.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegistrationComponent } from './registration/registration.component';
import { LoginComponent } from './login/login.component';
import { PaymentInformationComponent } from './payment-information/payment-information.component';
import { PlanSelectionComponent } from './plan-selection/plan-selection.component';
import { RoleSelectionComponent } from './role-selection/role-selection.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { UserRoutingModule } from './user.routing';
import { PaymentSuccessComponent } from './subscription-pages/payment-success/payment-success.component';
import { StripePricingTableComponent } from './stripe-pricing-table/stripe-pricing-table.component';
import { SubscriptionUpdatedComponent } from './subscription-pages/subscription-updated/subscription-updated.component';
import { SubscriptionCanceledComponent } from './subscription-pages/subscription-canceled/subscription-canceled.component';
import { SubscriptionPausedComponent } from './subscription-pages/subscription-paused/subscription-paused.component';
import { SubscriptionResumedComponent } from './subscription-pages/subscription-resumed/subscription-resumed.component';
import { UserInfoComponent } from './user-info/user-info.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
  declarations: [
    UserDetailsComponent,
    RoleSelectionComponent,
    PlanSelectionComponent,
    PaymentInformationComponent,
    RegistrationComponent,
    LoginComponent,
    PaymentSuccessComponent,
    StripePricingTableComponent,
    SubscriptionUpdatedComponent,
    SubscriptionCanceledComponent,
    SubscriptionPausedComponent,
    SubscriptionResumedComponent,
    UserInfoComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    GeneralModule,
    ReactiveFormsModule,
    UserRoutingModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    RegistrationComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UserModule { }

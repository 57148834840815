<app-loading *ngIf="!hasMoneyFlowData"></app-loading>
<div [hidden]="!hasMoneyFlowData">
    <div class="padding-top-15 padding-bottom-25">
        <label>Timeline:</label>
        <select [(ngModel)]="selectedTimeline" (change)="onTimelineSelection()">
            <option value="1mo">1 Months</option>
            <option value="3mo">3 Months</option>
            <option value="6mo">6 Months</option>
            <option value="1y">1 Years</option>
            <option value="2y">2 Years</option>
            <option value="5y">5 Years</option>
            <option value="10y">10 Years</option>
            <option value="ytd">Year To Day</option>
            <option value="max">All</option>
        </select>
    </div>
    <div class="container">
        <app-ticker-checker (tickerSelected)="onTickerSelected($event)"></app-ticker-checker>
        <div>
            <div>
                <app-generic-timeline-chart [data]="data"
                    [categories]="['EQUITY', 'CRYPTOCURRENCY', 'FUTURE', 'INDEX', 'ETF', 'CURRENCY']"
                    chartId="my-timeline-chart">
                </app-generic-timeline-chart>
            </div>
            <app-loading *ngIf="!hasData"></app-loading>
            <div class="graph"
                *ngIf="hasData && aiReaction && (selectedTimeline.includes('1mo') || selectedTimeline.includes('3mo') || selectedTimeline.includes('6mo'))">
                <app-prompt-ui [aiReaction]="aiReaction"></app-prompt-ui>
            </div>
        </div>
    </div>
</div>
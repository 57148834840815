import { Component } from '@angular/core';
import { LoadingService } from 'src/app/services/loading-service/loading.service';
import { MoneyFlowTimelineService } from 'src/app/services/money-flow-timeline/money-flow-timeline.service';
import { MoneyFlowTimeline } from '../interface/money-flow-timeline';
import { MarketReactionService } from 'src/app/services/market-reaction/market-reaction.service';
import { MetaService } from 'src/app/services/meta/meta.service';

@Component({
  selector: 'app-money-flow-timeline',
  templateUrl: './money-flow-timeline.component.html',
  styleUrl: './money-flow-timeline.component.scss'
})
export class MoneyFlowTimelineComponent {
  data: MoneyFlowTimeline[] = [];
  selectedTimeline: string = '3mo';
  hasData = false;
  hasMoneyFlowData = false;
  aiReaction: string | Promise<string>;
  selectedTicker: any = null;
  assets = [
    { "type": "CRYPTOCURRENCY", "name": "Bitcoin", "symbol": "BTC-USD" },
    { "type": "FUTURE", "name": "Gold", "symbol": "GC=F" },
    { "type": "FUTURE", "name": "Crude Oil", "symbol": "CL=F" },
    { "type": "INDEX", "name": "US 10Y Treasury", "symbol": "^TNX" },
    { "type": "ETF", "name": "Vanguard Real Estate ETF", "symbol": "VNQ" },
    { "type": "CURRENCY", "name": "USD/JPY", "symbol": "JPY=X" },
    { "type": "EQUITY", "name": "S&P 500", "symbol": "^GSPC" },
    { "type": "EQUITY", "name": "NVIDIA", "symbol": "NVDA" }
  ]
  constructor(
    private metaService: MetaService,
    private loadingService: LoadingService,
    private moneyFlowTimelineService: MoneyFlowTimelineService,
    private marketReactionService: MarketReactionService) { }

  ngOnInit(): void {
    // Fetch data and render chart
    this.fetchData(this.selectedTimeline);
    this.metaService.setMetaTags({
      title: 'Money Flow Timeline',
      description: 'Money Flow Timeline offers a detailed, chronological view for financial activities. This feature visualizes the flow of money over time, track and analyze trends, patterns, and key financial events.',
      keywords: "stocks, inflation, crash, market, money, flow"
    });
  }

  onTimelineSelection(): void {
    this.fetchData(this.selectedTimeline)
  }


  private fetchData(timeline: string): void {
    this.hasMoneyFlowData = false;
    this.loadingService.show();
    this.moneyFlowTimelineService.getMoneyFlowTimeline(timeline, this.assets).subscribe(
      data => {
        this.hasMoneyFlowData = true;
        this.data = data;
        this.getAnalysis(this.data);
        this.loadingService.hide();
      },
      error => {
        console.error('Error money flow timeline data:', error);
        this.loadingService.hide();
        this.hasMoneyFlowData = false;
      }
    );
  }

  getAnalysis(data: MoneyFlowTimeline[]) {
    this.hasData = false;
    this.aiReaction = null;
    this.loadingService.showSection();
    this.marketReactionService.getMoneyFlowTimelineReaction(data)
      .subscribe(
        response => {
          this.aiReaction = this.marketReactionService.convertAiToHtml(response.toString());
          this.hasData = true;
          this.loadingService.hideSection();
        },
        error => {
          console.error('Error fetching greed fear index reaction:', error);
          this.loadingService.hideSection();
          this.hasData = false;
        }
      );
  }

  // Function to add a new stock under the "Stocks" category
  addStockToCategory(type: string, stockName: string, ticker: string) {
    // Check if type and symbol already exist
    let stockExists = false;

    // Loop through the existing assets to check if the stock already exists in the flattened structure
    for (let asset of this.assets) {
      if (asset.type === type && asset.symbol === ticker) {
        // If the stock with the same symbol already exists, do not add it
        stockExists = true;
        break;
      }
    }

    // If the stock doesn't exist (type + name + symbol), add it to the assets array
    if (!stockExists) {
      this.assets.push({
        type: type,
        name: stockName,
        symbol: ticker,
      });
      this.fetchData(this.selectedTimeline);
    }
  }

  onTickerSelected(ticker: any): void {
    // Add the selected ticker to the category using the flattened structure
    this.addStockToCategory(ticker.quoteType, ticker.name, ticker.symbol);

    // Set the selected ticker for any other processing (e.g., display it)
    this.selectedTicker = ticker;
  }

  onAssetRemoved(asset: any): void {
    console.log(`Asset removed: ${asset}`);
    // Perform additional actions as needed
    const assetIndex = this.assets.findIndex((a) => a.symbol === asset);
    if (assetIndex !== -1) {
      this.assets.splice(assetIndex, 1);
    }
  }
}
